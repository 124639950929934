import React, { useState, useRef, useEffect } from 'react';
import {
  VStack,
  HStack,
  Image,
  Text,
  Center,
  Container,
  IconButton,
  Heading,
  Flex,
  SimpleGrid,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid,
  GridItem,
  Spacer,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Header, Footer } from '../components/molecules/index';
import { Link } from 'gatsby';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { TextUnderline, GFLButton } from '../components/atoms';
import { GFLTextFilledForm } from '../components/atoms';
import GFLTextArea from '../components/atoms/GFLTextArea';
import { BsArrowRight, BsPeople, BsPeopleFill } from 'react-icons/bs';
import {
  BiCurrentLocation,
  BiPackage,
  BiPhoneCall,
  BiTime,
} from 'react-icons/bi';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from '../components/molecules/Loader';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import { MdEmail } from 'react-icons/md';
import TestiShape from '../assets/svgs/TestiShape';
import { FaFax } from 'react-icons/fa';
import { useWindowSize } from '../hooks/index';

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);

const services = [
  {
    key: 'Sea Freight',
    content:
      'GFL Group provides flexible and integrated ocean freight services to all ports around the world. ',
    imgUrl: '/services/all-services/AllServices-01.jpg',
    href: '/services/#sea-freight',
  },
  {
    key: 'Air Freight',
    content:
      'GFL Group has close connections with various air freight partners to offer and coordinate freight arrangements.',
    imgUrl: '/services/all-services/AllServices-02.jpg',
    href: '/services/#air-freight',
  },
  {
    key: 'Customs Clearance',
    content:
      'Ensure your goods declared and recorded in accordance to Customs Ruling in customs books.',
    imgUrl: '/services/all-services/AllServices-03.jpg',
    href: '/services/#customs-clearance',
  },
  {
    key: 'Project Cargo Handling',
    content: 'Personalized solution on project logistics management.',
    imgUrl: '/services/all-services/AllServices-04.jpg',
    href: '/services/#project-cargo-handling',
  },
  {
    key: 'Transloading',
    content:
      'Involve more than one more of transportation during the goods transfer process.',
    imgUrl: '/services/all-services/AllServices-05.jpg',
    href: '/services/#transloading',
  },
  {
    key: 'Land Transportation',
    content:
      'Safe and seamless cargo transportation experience from point A to point B',
    imgUrl: '/services/all-services/AllServices-06.jpg',
    href: '/services/#land-transportation',
  },
  {
    key: 'Marine Insurance',
    content: 'Protection to your cargo with our marine insurance.',
    imgUrl: '/services/all-services/AllServices-07.jpg',
    href: '/services/#marine-insurance',
  },
  {
    key: 'Warehousing & Distribution',
    content:
      'Proper warehouse facilities and distribution system with experienced skilled workforce.',
    imgUrl: '/services/all-services/AllServices-08.jpg',
    href: '/services/#warehousing-and-distribution',
  },
  {
    key: 'Value-Added',
    content:
      'Additional services required by business owners before moving their goods around.',
    imgUrl: '/services/all-services/AllServices-09.jpg',
    href: '/services/#value-added',
  },
];

export const testimonials = [
  {
    key: '1',
    imgUrl: '/home/testimonial/testi logo x4-03.png',
    name: 'Plasmatic Pacific Sdn Bhd',
    company: 'Plasmatic Pacific Sdn Bhd',
    position: 'Mr. Harry',
    content:
      'It is a great comfort working with GFL Group. Their customer service knows our working style well and accommodate to our requirements without hassle. Very professional and accountable logistics service provider. ',
  },
  {
    key: '2',
    imgUrl: '/home/testimonial/testi logo x4-02.svg',
    name: 'Perbeh Marketing Sdn Bhd',
    company: 'Perbeh Marketing Sdn Bhd',
    position: 'Mr. Kenneth',
    content:
      'We would like to acknowledge and recognize the outstanding service supplied by GFL Group team. We work seamlessly and communicate with the same frequency. Never miss to do their follow ups by e-mails and phone calls to resolve matters. We thank GFL Group for the reliable and professional service.',
  },
  ,
  {
    key: '3',
    imgUrl: '/home/testimonial/testi logo x4-04.png',
    name: 'Verdant Solar Sdn Bhd',
    company: 'Verdant Solar Sdn Bhd',
    position: 'Mr. Zeth',
    content:
      'GFL Group is a reliable partner. We have been using their services for years now, they provide best support & 5 stars customer service. Highly recommended.',
  },
  {
    key: '4',
    imgUrl: '/home/testimonial/testi logo x4-01.svg',
    name: 'RBI Manufacturing Sdn Bhd',
    company: 'RBI Manufacturing Sdn Bhd',
    position: 'Mr. Ng',
    content:
      'Partnering with GFL Group has been extraordinary. Their approach to understand the importance of customer experience and providing professional advices fit us well. We enjoy working with GFL Group and wish them greater success.',
  },
];

const Index = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const collabStickyRef = useRef(null);

  const [onFocus, setOnFocus] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { width, height } = useWindowSize();

  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader /> <Header />
      <Swiper
        pagination={{
          clickable: true,
        }}
        id="splash-slider"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={true}
        onBeforeInit={(swiper) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          // @ts-ignore
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        slidesPerView={1}
        className="mySwiper"
        style={{
          width: '100%',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          height: width < 768 ? (width / 3) * 4 : '90vh',
        }}
      >
        <SwiperSlide>
          <VStack
            backgroundRepeat={'no-repeat'}
            backgroundPosition="center center"
            backgroundSize="cover"
            backgroundImage="/home/1425x675-1.jpg"
            w="100%"
            h="100%"
            alignItems={'flex-start'}
            justifyContent="center"
            pl={['3em', '3em', '8em']}
            pr={['3em', '3em', '8em']}
            pt={['1.2em', '1.2em', 0]}
            id="slide-zoom"
          >
            <VStack
              color="#FFFFFF"
              alignItems={'flex-start'}
              w={['100%', '100%', '50%']}
              spacing="18px"
              zIndex={4}
            >
              <VStack gap="0" alignItems={'flex-start'}>
                <Heading
                  fontSize={['1.8rem', '2.5rem', '4rem']}
                  color="#FFFFFF"
                  className="title-animation"
                  textAlign={'start'}
                >
                  Welcome To GFL Group
                </Heading>
              </VStack>
              <Text
                fontSize={['0.9rem', '1.0rem', '1.2rem']}
                fontWeight="500"
                textAlign={'start'}
                w={['45%', '75%', '100%']}
              >
                Your Preferred Logistics Provider
              </Text>
              <Flex flexDir={['column', 'column', 'row']} gap="20px">
                <Link to="/services">
                  <HStack cursor={'pointer'}>
                    <Image
                      src={
                        'https://morz.vamtam.com/wp-content/uploads/2018/01/Button-play-orange.svg'
                      }
                    />
                    <Text ml={'20px !important'}>Find out more</Text>
                  </HStack>
                </Link>
              </Flex>
            </VStack>
          </VStack>
        </SwiperSlide>
        <SwiperSlide>
          <VStack
            backgroundRepeat={'no-repeat'}
            backgroundPosition="center center"
            backgroundSize="cover"
            backgroundImage="/home/1425x675-2.jpg"
            w="100%"
            h="100%"
            alignItems={'flex-start'}
            justifyContent="center"
            pl={['3em', '3em', '8em']}
            pr={['3em', '3em', '8em']}
            pt={['1.2em', '1.2em', 0]}
            id="slide-zoom"
          >
            <VStack
              color="#FFFFFF"
              alignItems={'flex-start'}
              w={['100%', '100%', '50%']}
              spacing="18px"
              zIndex={4}
            >
              <VStack gap="0" alignItems={'flex-start'}>
                <Heading
                  fontSize={['1.8rem', '2.5rem', '4rem']}
                  color="#FFFFFF"
                  textAlign={'start'}
                  className="title-animation"
                >
                  The Logistics <br></br> Expert
                </Heading>
              </VStack>
              <Text
                fontSize={['0.9rem', '1.0rem', '1.2rem']}
                fontWeight="500"
                textAlign={'start'}
                w={['45%', '75%', '100%']}
              >
                Save the hassle, let our expertise create value for your
                business supply chain needs
              </Text>
              <Flex flexDir={['column', 'column', 'row']} gap="20px">
                <Link to="/services">
                  <HStack cursor={'pointer'}>
                    <Image
                      src={
                        'https://morz.vamtam.com/wp-content/uploads/2018/01/Button-play-orange.svg'
                      }
                    />
                    <Text ml={'20px !important'}>Find out more</Text>
                  </HStack>
                </Link>
              </Flex>
            </VStack>
          </VStack>
        </SwiperSlide>
        <SwiperSlide>
          <VStack
            backgroundRepeat={'no-repeat'}
            backgroundPosition="45% center"
            backgroundSize="cover"
            backgroundImage="/home/1425x675-3.jpg"
            w="100%"
            h="100%"
            alignItems={'flex-start'}
            justifyContent="center"
            pl={['3em', '3em', '8em']}
            pr={['3em', '3em', '8em']}
          >
            <VStack
              color="#FFFFFF"
              alignItems={'flex-start'}
              w={['100%', '100%', '50%']}
              spacing="18px"
              zIndex={4}
            >
              <VStack gap="0" alignItems={'flex-start'}>
                <Heading
                  fontSize={['1.8rem', '2.5rem', '4rem']}
                  color="#FFFFFF"
                  textAlign={'start'}
                  className="title-animation"
                >
                  Efficient & Reliable Logistics
                </Heading>
              </VStack>
              <Text
                fontSize={['0.9rem', '1.0rem', '1.2rem']}
                fontWeight="500"
                textAlign={'start'}
                w={['45%', '75%', '100%']}
              >
                We value customers needs as our commitments with <br></br>{' '}
                efficiency, transparency and reliability
              </Text>
              <Flex flexDir={['column', 'column', 'row']} gap="20px">
                {/* <GFLButton
                  bgColor="#213A71"
                  border="none !important"
                  color="#FFFFFFF"
                  py="2em"
                  px="2.5em"
                  onClick={() => {
                    window.location.href = '/services';
                  }}
                >
                  Our Services
                </GFLButton> */}
                <Link to="/services">
                  <HStack cursor={'pointer'}>
                    <Image
                      src={
                        'https://morz.vamtam.com/wp-content/uploads/2018/01/Button-play-orange.svg'
                      }
                    />
                    <Text ml={'20px !important'}>Find out more</Text>
                  </HStack>
                </Link>
              </Flex>
            </VStack>
          </VStack>
        </SwiperSlide>

        <IconButton
          ref={navigationPrevRef}
          aria-label="Prev Slide"
          icon={<ChevronLeftIcon />}
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '12px',
            zIndex: 1001,
            backgroundColor: '#ffffff',

            boxShadow: '0px 0px 10px #e9e9e9',
          }}
        />
        <IconButton
          ref={navigationNextRef}
          aria-label="Next Slide"
          icon={<ChevronRightIcon />}
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '12px',
            zIndex: 1001,
            backgroundColor: '#ffffff',

            boxShadow: '0px 0px 10px #e9e9e9',
          }}
        />
      </Swiper>
      <Container maxW="container.xl" pt="4em" pb="4em">
        <SimpleGrid columns={[1, 1, 2]} gap={'1.4rem'}>
          <HStack justifyContent={'flex-start !important'} w="100%">
            <Image
              src={'/logo/GFL_GROUP_LIGHT.svg'}
              alt="GFL Logo"
              width={'85%'}
              height={'100%'}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.location.href = '/';
                }
              }}
              cursor="pointer"
            />
          </HStack>
          <VStack w={'100%'} alignItems={'flex-start'}>
            <Heading fontSize={'3rem'} color="#213A71" w="100%">
              WHO ARE WE
            </Heading>
            <Text fontWeight={'bold'} fontSize={'xl'} color="black">
              Your Solution to Total Cargo Transportation
            </Text>

            <br></br>

            <Text>
              GFL Group is a trusted Malaysian based (Port Klang) logistics
              service provider established since year 1991. <br></br> <br></br>{' '}
              GFL Group offers complete and comprehensive logistics services
              across all spectrums in the supply chain. With the home court
              advantage & logistics expertise, we help our clients to grow
              sustainably by ensuring their logistics needs are well taken of.
            </Text>
          </VStack>
        </SimpleGrid>
      </Container>
      <Center backgroundColor="#213A71">
        <SimpleGrid
          columns={[1, 1, 2]}
          w="100%"
          spacing="10"
          justifyContent={'space-between'}
        >
          <Container
            maxW="container.xl"
            pt="6em"
            pb={['2em', '4em', '7em']}
            px={['1em', '1em', '0em']}
            pl={['1em', '1em', '5em']}
          >
            <VStack w="100%" spacing={10}>
              <Heading
                as="h2"
                color="white"
                fontSize={['1.5rem', '1.8rem', '2.4rem']}
              >
                Why Choose Us
              </Heading>
              <SimpleGrid columns={[1, 1, 2]} spacingY={'50px'} w="100%">
                <VStack w="100%" spacing={2} alignItems={'center'}>
                  <Image
                    src={
                      '/home/why-choose-us/Experienced & Enthusiastic Staffs.svg'
                    }
                    alt=""
                    h="60px"
                    w="60px"
                  />
                  <Heading
                    as="h2"
                    color="white"
                    fontSize={'1rem'}
                    textAlign={'center'}
                  >
                    Experienced & Enthusiastic <br></br> Staffs
                  </Heading>
                </VStack>

                <VStack w="100%" spacing={2} alignItems={'center'}>
                  <Image
                    src={
                      '/home/why-choose-us/Flexible and Customized Transportation Solutions.svg'
                    }
                    alt=""
                    h="60px"
                    w="60px"
                  />
                  <Heading
                    as="h2"
                    color="white"
                    fontSize={'1rem'}
                    textAlign={'center'}
                  >
                    Flexible and Customized <br></br> Transportation Solutions
                  </Heading>
                </VStack>

                <VStack w="100%" spacing={2} alignItems={'center'}>
                  <Image
                    src={'/home/why-choose-us/Highly Competitive Pricing.svg'}
                    alt=""
                    h="60px"
                    w="60px"
                  />
                  <Heading
                    as="h2"
                    color="white"
                    fontSize={'1rem'}
                    textAlign={'center'}
                  >
                    Highly Competitive Pricing
                  </Heading>
                </VStack>

                <VStack w="100%" spacing={2} alignItems={'center'}>
                  <Image
                    src={'/home/why-choose-us/Proven Industry Expertise.svg'}
                    alt=""
                    h="60px"
                    w="60px"
                  />
                  <Heading
                    as="h2"
                    color="white"
                    fontSize={'1rem'}
                    textAlign={'center'}
                  >
                    Proven Industry Expertise
                  </Heading>
                </VStack>

                <VStack w="100%" spacing={2} alignItems={'center'}>
                  <Image
                    src={'/home/why-choose-us/Trusted & Reliable Service.svg'}
                    alt=""
                    h="60px"
                    w="60px"
                  />
                  <Heading
                    as="h2"
                    color="white"
                    fontSize={'1rem'}
                    textAlign={'center'}
                  >
                    Trusted & Reliable Service
                  </Heading>
                </VStack>

                <VStack w="100%" spacing={2} alignItems={'center'}>
                  <Image
                    src={'/earth.svg'}
                    alt=""
                    h="60px"
                    w="60px"
                    filter={'invert(1) grayscale(100%) brightness(200%)'}
                  />
                  <Heading
                    as="h2"
                    color="white"
                    fontSize={'1rem'}
                    textAlign={'center'}
                  >
                    Worldwide Logistics Network
                  </Heading>
                </VStack>
              </SimpleGrid>
              <Spacer />
              <Heading
                fontSize="1.4rem"
                color="white"
                mt="10"
                mb="10"
                textAlign={'center'}
              >
                Leave your Logistics Matters to us
              </Heading>
            </VStack>
          </Container>
          <VStack overflow={'hidden'} cursor="pointer">
            <Center
              w="100%"
              h="100%"
              backgroundImage={'/home/692x742.jpg'}
              backgroundAttachment="scroll"
              backgroundSize="cover"
              backgroundRepeat={'no-repeat'}
              backgroundPosition={'right top'}
              transition="transform .6s"
              _hover={{
                transform: 'scale(1.05)',
              }}
            ></Center>
          </VStack>
        </SimpleGrid>
      </Center>
      <Center py={['2em', '2.5em', '5em']}>
        <Container maxW="container.xl">
          <Flex
            flexDir={['column', 'column', 'row']}
            gap="1em"
            position="relative"
          >
            <VStack
              w={['100%', '100%', '20%', '20%']}
              alignItems={'flex-start'}
            >
              <VStack
                w="100%"
                alignItems={['center', 'center', 'flex-start', 'flex-start']}
              >
                <Heading fontSize={['1.5rem', '1.8rem', '2.4rem']}>
                  Our services
                </Heading>
                <TextUnderline w="40%" />
              </VStack>
              <VStack
                d={['none', 'none', 'flex', 'flex']}
                alignItems={'flex-start'}
              >
                {services.map((service, index) => (
                  <Link className="all-services-link" to={service.href}>
                    <Box className="all-services-indicator" />
                    <Text>{service.key}</Text>
                  </Link>
                ))}
              </VStack>
            </VStack>

            <IconButton
              ref={navigationPrevRef}
              aria-label="Prev Slide"
              icon={<ChevronLeftIcon style={{ color: 'white', zIndex: 2 }} />}
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateX(10%) translateY(-50%)',
                left: width < 768 ? '-10px' : '19%',
                zIndex: 2,
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />

            <IconButton
              ref={navigationNextRef}
              aria-label="Next Slide"
              icon={<ChevronRightIcon style={{ color: 'white' }} />}
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateX(10%) translateY(-50%)',
                right: '0px',
                zIndex: 2,
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />

            <Swiper
              autoplay={true}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                // @ts-ignore
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                // @ts-ignore
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              slidesPerView={width < 768 ? 1 : width < 1000 ? 2 : 3}
              className="mySwiper"
              style={{
                width: width < 768 ? '100%' : width < 1000 ? '90%' : '80%',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingBottom: '30px',
              }}
            >
              {services.map((service, index) => (
                <SwiperSlide
                  style={{
                    paddingRight: width < 768 ? '0px' : '20px',
                  }}
                >
                  <VStack overflow={'hidden'} cursor="pointer" gap={'0px'}>
                    <Image
                      src={service.imgUrl}
                      alt=""
                      w="100%"
                      transition="transform .6s"
                      _hover={{
                        transform: 'scale(1.05)',
                      }}
                    />

                    <VStack
                      justifyContent={'flex-start'}
                      alignItems={'flex-start'}
                      px="1.5em"
                      py="1.5em"
                      backgroundColor="rgb(33, 58, 113)"
                      height={'250px'}
                      color={'white'}
                      position="relative"
                      style={{
                        marginTop: '0px',
                      }}
                    >
                      <Text
                        className="color-in-text"
                        textAlign={'start'}
                        fontSize={'1.5rem'}
                        position={'absolute'}
                        top={'0'}
                        right={'30px'}
                        transform={'translateY(-50%)'}
                        fontFamily={'Work Sans'}
                        fontWeight={'bolder'}
                        fontSize={'80px'}
                      >
                        {index + 1}
                      </Text>
                      <Heading
                        textAlign={'start'}
                        color="white"
                        fontSize={'24px'}
                        marginBottom={'8px'}
                      >
                        {service.key}
                        <br></br>
                      </Heading>

                      <Text textAlign={'start'} fontSize="md" color="#D4D4D4">
                        {service.content}
                      </Text>

                      <a
                        style={{
                          position: 'absolute',
                          bottom: '25px',
                          left: '25px',
                          color: '#A1C5FF',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className={'home-service-see-more'}
                        href={service.href}
                      >
                        See more
                        <BsArrowRight
                          className="home-service-arrow-right"
                          style={{
                            marginLeft: '0.5em',
                            transition: 'all 150ms ease-in-out',
                          }}
                        />
                      </a>
                    </VStack>
                  </VStack>
                </SwiperSlide>
              ))}
            </Swiper>
          </Flex>
        </Container>
      </Center>
      <Center
        backgroundSize="contain"
        backgroundPosition={'center center'}
        backgroundRepeat="no-repeat"
        backgroundImage="https://morz.vamtam.com/wp-content/uploads/2018/01/map-dots-trans.svg"
        py={['2em', '2.5em', '5em']}
        backgroundColor="#fafafa"
      >
        <Container maxW="container.xl">
          <HStack w="100%" justifyContent={'center'} mb={['4em', '4em', '7em']}>
            <Text fontSize={['1.5rem', '1.8rem', '2.4rem']} color="black">
              <span style={{ fontWeight: '700', color: '#213A71' }}>
                Performance
              </span>{' '}
              Dashboard
            </Text>
          </HStack>
          <Flex
            justifyContent={'center'}
            alignItems={['center', 'center', 'flex-start']}
            flexDir={['column', 'column', 'row']}
            gap={['4em', '4em', '2em']}
            w="100%"
          >
            <VStack alignItems={['center', 'center', 'flex-start']}>
              <Image src={'/custom-icons/parcel.svg'} />
              <Heading color="#213A71" fontSize="3.2rem">
                <CountUp end={100} start={onFocus ? 0 : -1}>
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        isVisible && setOnFocus(true);
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                K+
              </Heading>
              <Text
                fontWeight={'bold'}
                fontSize={'1.2rem'}
                textAlign={['center', 'center', 'start']}
              >
                No. of TEUs
              </Text>
              <Text textAlign={['center', 'center', 'start']}>
                We strongly support best practice sharing across our operations
                around the world and across various industrial sectors.
              </Text>
            </VStack>
            <VStack alignItems={['center', 'center', 'flex-start']}>
              <Image src={'/custom-icons/earth.svg'} />
              <Heading color="#213A71" fontSize="3.2rem">
                <CountUp end={50} start={onFocus ? 0 : -1}>
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        isVisible && setOnFocus(true);
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                +
              </Heading>
              <Text
                fontWeight={'bold'}
                fontSize={'1.2rem'}
                textAlign={['center', 'center', 'start']}
              >
                Agents/Branches
              </Text>
              <Text textAlign={['center', 'center', 'start']}>
                We design and advise industry logistics solutions to help client
                in their supply chain management
              </Text>
            </VStack>
            <VStack alignItems={['center', 'center', 'flex-start']}>
              <BsPeopleFill style={{ fontSize: '3.3rem', color: '#213A71' }} />
              <Heading color="#213A71" fontSize="3.2rem">
                <img
                  style={{ height: '64px', width: '64px' }}
                  src={'/home/infinity.svg'}
                />
              </Heading>
              <Text
                fontWeight={'bold'}
                fontSize={'1.2rem'}
                textAlign={['center', 'center', 'start']}
              >
                Satisfied Clients
              </Text>
              <Text textAlign={['center', 'center', 'start']}>
                GFL applies its operational expertise to provide best-in-class
                services across its integrated worldwide network.
              </Text>
            </VStack>
          </Flex>
        </Container>
      </Center>
      <Image src={'/home/map.png'} />
      <Center py="3em" backgroundColor="#fafafa">
        <Container maxW="container.xl" px={[0, 0, '14px']} position="relative">
          <VStack gap="1.2em" paddingX={['20px', '20px', '0px']}>
            <VStack>
              <Heading fontSize={['1.5rem', '1.8rem', '1.8rem']}>
                Testimonials
              </Heading>
              <Text color={'#000'}>What People and Clients Think About Us</Text>
              <br />
              <TextUnderline w="40%" />
            </VStack>

            <IconButton
              ref={navigationNextRef}
              aria-label="Next Slide"
              icon={<ChevronRightIcon style={{ color: 'white' }} />}
              style={{
                position: 'absolute',
                top: '50%',
                transform:
                  width < 768
                    ? 'translateX(-40%) translateY(20%)'
                    : 'translateX(-40%) translateY(-30%)',
                right: '0px',
                zIndex: 1001,
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />

            <Swiper
              autoplay={true}
              loop={true}
              navigation={{
                nextEl: navigationNextRef.current,
              }}
              pagination={{
                clickable: true,
              }}
              onBeforeInit={(swiper) => {
                // @ts-ignore
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              slidesPerView={width < 768 ? 1 : width < 1000 ? 2 : 2}
              className="mySwiper"
              style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingBottom: '30px',
              }}
            >
              {testimonials.map((test, index) => (
                <SwiperSlide
                  style={{
                    paddingRight: width < 768 ? '0px' : '15px',
                    position: 'relative',
                  }}
                  key={index}
                >
                  <Flex
                    flexDir={['column', 'column', 'row']}
                    overflow={'hidden'}
                    cursor="pointer"
                    gap={'0px'}
                    backgroundColor="rgb(33, 58, 113)"
                    height={width < 1000 ? '100%' : '275px'}
                    paddingX={['20px', '20px', '0px', '0px']}
                    style={{
                      backgroundImage: 'url(/home/testimonial/test-shape.svg)',
                      backgroundAttachment: 'fixed',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  >
                    <Heading
                      d={['none', 'none', 'none', 'flex']}
                      position={'absolute'}
                      bottom="20px"
                      right="40px"
                      color={'#FFFFFF'}
                      fontSize="18px"
                    >
                      {test?.position}
                    </Heading>
                    <VStack
                      w={['100%', '100%', '40%', '25%']}
                      height={'100%'}
                      padding={'20px'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                    >
                      <Image
                        src={test!.imgUrl}
                        alt=""
                        w="80%"
                        transition="transform .6s"
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                      />
                    </VStack>

                    <VStack
                      w={['100%', '75%', '75%', '75%']}
                      justifyContent={'flex-start'}
                      alignItems={'flex-start'}
                      pr="1.5em"
                      py="1.5em"
                      height={'100%'}
                      color={'white'}
                      position="relative"
                      style={{
                        marginTop: '0px',
                      }}
                    >
                      <Heading
                        textAlign={'start'}
                        color="white"
                        fontSize={'24px'}
                        marginBottom={'8px'}
                      >
                        {test!.name}
                        <br></br>
                      </Heading>

                      <Heading
                        d={['flex', 'flex', 'flex', 'none']}
                        bottom="20px"
                        right="40px"
                        color={'#FFFFFF'}
                        fontSize="18px"
                      >
                        {test?.position}
                      </Heading>

                      <Text textAlign={'start'} color="#FFFFFF">
                        {test!.content}
                      </Text>
                    </VStack>
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </VStack>
        </Container>
      </Center>
      <Center bgColor={'#213A71'}>
        <Container maxW={'container.xl'}>
          <SimpleGrid columns={[1, 1, 3]} background="#213A71">
            <VStack gap="1.5em" py="40px">
              <VStack>
                <Heading
                  fontSize={['1.5rem', '1.8rem', '1.8rem']}
                  color="#FFFFFF"
                >
                  Members Of
                </Heading>
              </VStack>
              <VStack w="100%" spacing={'10px'} justifyContent={'center'}>
                <SimpleGrid columns={[1, 1, 2]}>
                  <Image
                    src={'/home/partners/Our Partner-01.png'}
                    h="150px"
                    w="150px"
                  />
                  <Image
                    src={'/home/partners/Our Partner-02.png'}
                    h="150px"
                    w="150px"
                  />
                </SimpleGrid>

                <Image
                  src={'/home/partners/Our Partner-03.png'}
                  h="150px"
                  w="150px"
                />
              </VStack>
            </VStack>

            <VStack gap="1.5em" py="40px">
              <VStack>
                <Heading
                  fontSize={['1.5rem', '1.8rem', '1.8rem']}
                  color="#FFFFFF"
                >
                  Status
                </Heading>
              </VStack>
              <VStack w="100%" spacing={'10px'} justifyContent={'center'}>
                <Image
                  src={'/home/partners/Our Partner-04.png'}
                  h="150px"
                  w="150px"
                />
                <Image
                  src={'/home/partners/Our Partner-05.png'}
                  h="150px"
                  w="150px"
                />
              </VStack>
            </VStack>

            <VStack gap="1.5em" py="40px">
              <VStack>
                <Heading
                  fontSize={['1.5rem', '1.8rem', '1.8rem']}
                  color="#FFFFFF"
                >
                  Working Partners
                </Heading>
              </VStack>
              <VStack w="100%" spacing={'10px'} justifyContent={'center'}>
                <SimpleGrid columns={[1, 1, 2]}>
                  <Image
                    src={'/home/partners/Our Partner-06.png'}
                    h="150px"
                    w="150px"
                  />
                  <Image
                    src={'/home/partners/Our Partner-07.png'}
                    h="150px"
                    w="150px"
                  />
                </SimpleGrid>

                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                  <ModalOverlay />
                  <ModalContent
                    style={{
                      maxHeight: '60vh',
                      overflowY: 'auto',
                      marginTop: '130px',
                      width: width < 768 ? '90%' : '100%',
                    }}
                  >
                    <ModalHeader>Our Partners</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Grid
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(3, 1fr)',
                        ]}
                        gap={[2, 4, 6]}
                      >
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/1.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/2.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/3.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/4.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/5.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/6.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/7.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/8.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                        <GridItem>
                          <Image
                            src={'/home/partners/see-more/9.png'}
                            h={['100%', '100%', '150px']}
                            w={['100%', '100%', '150px']}
                          />
                        </GridItem>
                      </Grid>
                    </ModalBody>
                  </ModalContent>
                </Modal>

                <SimpleGrid columns={[1, 1, 2]}>
                  <Image
                    src={'/home/partners/Our Partner-08.png'}
                    h="150px"
                    w="150px"
                  />
                  <Box className="partner-see-more-wrapper">
                    <Box
                      style={{
                        borderRadius: '50%',
                        backgroundColor: '#fff !important',
                      }}
                      className="partner-see-more"
                      onClick={onOpen}
                    >
                      See More
                    </Box>
                  </Box>
                </SimpleGrid>
              </VStack>
            </VStack>
          </SimpleGrid>
        </Container>
      </Center>
      <Container maxW="1200px" w="100%" textAlign={'center'} mt="45px">
        <Center d="flex" flexDirection={'column'}></Center>

        <SimpleGrid columns={[1, 1, 2]}>
          <VStack
            w="100%"
            px={['1rem', '1rem', '2rem']}
            py="3em"
            spacing={5}
            alignItems={['center', 'center', 'flex-start']}
          >
            <Heading textAlign={'start'}>
              {width < 768 ? 'Contact' : 'Contact Information'}
            </Heading>
            <HStack
              style={{
                marginTop: '0.8em',
              }}
              backgroundColor="#213A71"
              w="20%"
              h="5px"
            ></HStack>
            <br />
            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={['flex-start', 'flex-start', 'flex-start']}
              spacing={[4, 6, 8]}
            >
              <BiPhoneCall
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading size={['sm', 'md', 'md']}>+ 603-3162 5454</Heading>
                <Text textAlign={'start'}>Don't hesitate to contact us!</Text>
              </VStack>
            </HStack>

            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={[4, 6, 8]}
            >
              <FaFax
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading size={['sm', 'md', 'md']}>+ 603-3162 5445</Heading>
                <Text textAlign={'start'}>Company Fax</Text>
              </VStack>
            </HStack>

            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={[4, 6, 8]}
            >
              <MdEmail
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading
                  size={['sm', 'md', 'md']}
                  textAlign="start"
                  textTransform="none"
                >
                  info@isc-gfl.com.my
                </Heading>
                <Text textAlign={'start'}>Email us to ask any questions!</Text>
              </VStack>
            </HStack>

            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={[4, 6, 8]}
            >
              <BiTime
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading size={['sm', 'md', 'md']}>Office Hours</Heading>
                <Text textAlign={'start'}>
                  Mon - Fri: 9:00 - 17:30 / Sat: 9:00 - 12:30
                </Text>
                <Text textAlign={'start'}>Close on Sundays</Text>
              </VStack>
            </HStack>
          </VStack>

          <VStack
            w="100%"
            background={'#FFF'}
            alignItems={'flex-start'}
            px={['1rem', '1rem', '2rem']}
            py="3em"
          >
            <Heading textAlign={'start'}>
              Let Us Know About Your Inquiry{' '}
            </Heading>
            <HStack
              style={{
                marginTop: '0.8em',
              }}
              backgroundColor="#213A71"
              w="20%"
              h="5px"
            ></HStack>
            <br />
            <Text
              fontSize={'1.3rem'}
              color="black"
              fontWeight={'normal'}
              textAlign={'start'}
            >
              Please feel free to fill in the form below and we will get back to
              you in our shortest time.
            </Text>
            <Formik
              initialValues={{
                name: '',
                company: '',
                email: '',
                phone: '',
                help_desc: '',
              }}
              onSubmit={(data) => {
                let fullBody = `${data.help_desc} sent from ${data.name} (${data.company}) - ${data.email} - ${data.phone}`;
                window.location.href = `mailto:info@isc-gfl.com.my?subject=From Website&body=${fullBody}`;
              }}
              enableReinitialize
            >
              {(props) => (
                <Form style={{ width: '95%' }}>
                  <VStack spacing="1.5em" w="100%">
                    <Flex
                      flexDir={['column', 'column', 'row']}
                      gap="1em"
                      w="100%"
                    >
                      <Field
                        name="name"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Name'}
                        value={props.values.name}
                      />
                      <Field
                        name="company"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Company'}
                        value={props.values.company}
                      />
                    </Flex>
                    <Flex
                      flexDir={['column', 'column', 'row']}
                      gap="1em"
                      w="100%"
                    >
                      <Field
                        name="email"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Email Address'}
                        value={props.values.email}
                      />
                      <Field
                        name="phone"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Contact Number'}
                        value={props.values.phone}
                      />
                    </Flex>
                    <Field
                      name="help_desc"
                      component={GFLTextArea}
                      onChange={props.handleChange}
                      placeholder={'Your Message'}
                      value={props.values.help_desc}
                    />
                    <HStack w="100%" justifyContent={'flex-start'}>
                      <GFLButton
                        color="#FFFFFF"
                        type="submit"
                        borderRadius={'0'}
                        // backgroundColor="#213A71"
                        py="16px"
                        px="25px"
                      >
                        Submit
                      </GFLButton>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </VStack>
        </SimpleGrid>
      </Container>
      <Footer />
    </>
  );
};

export default Index;
